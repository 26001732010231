<template>
  <div class="login-wrap">
    <div class="login-cont">
      <div class="login-scan">
        <img src="~@/assets/images/scan.jpg" alt="">
        <p>欢迎使用微信小程序操作更便捷！</p>
      </div>
      <div class="login-title">
        <div class="login-logo">
          <img src="~@/assets/images/3.png" alt="">
        </div>
        <div class="login-tilt-cont">
          <p class="login-title1">中国农产品质量安全</p>
          <p class="login-title2">承诺达标合格证追溯平台</p>
        </div>

      </div>
      <div class="login-cont-desc">
        <div class="login-img">
          <img src="~@/assets/images/2.png" alt="">
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="0" class="login-container">
          <h3 class="title">经营主体和生产主体登录</h3>
          <div v-show="loginType == 'account'">
            <el-form-item prop="userName" label="">
              <div id="userName">
                <el-input type="text" auto-complete="new-password" v-model="ruleForm.userName" placeholder="请输入账号" @focus="focusInput('userName')" @blur="blurInput('userName')">
                  <template slot="prepend">账号:</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password" label="">
              <div id="password">
                <el-input type="password" auto-complete="new-password" v-model="ruleForm.password" placeholder="请输入密码" @focus="focusInput('password')" @blur="blurInput('password')">
                  <template slot="prepend">密码:</template>
                </el-input>
              </div>
            </el-form-item>
            
            <el-row>
              <el-col :span="12">
                <el-checkbox class="remember" v-model="ruleForm.checked">记住密码</el-checkbox>
              </el-col>
              <el-col :span="12" class="code-box">
                <p class="link" @click="findaccount">忘记密码？</p>
              </el-col>
            </el-row>
            
          </div>
          <div v-show="loginType == 'phone'">
            <el-form-item prop="mobile" label="">
              <div id="mobile">
                <el-input type="text" auto-complete="new-password" v-model="ruleForm.mobile" placeholder="请输入手机号码" @focus="focusInput('mobile')" @blur="blurInput('mobile')">
                  <template slot="prepend">手机号码:</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="" prop="verificationCode">
              <div id="verificationCode">
                <el-input :class="sendFlag ? 'my-input-btn check-btn' :  'my-input-btn-gray check-btn'" auto-complete="new-password" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码" @focus="focusInput('verificationCode')" @blur="blurInput('verificationCode')">
                  <template slot="prepend">验证码:</template>
                  <el-button slot="append" v-if="sendFlag" @click="sendMsg('mobile')" type="primary">获取验证码</el-button>
                  <el-button slot="append" v-else class="btn-gray" type="primary">{{countdown}}秒后重新发送</el-button>
                </el-input>
              </div>
            </el-form-item>
          </div>
          <el-row class="pb-15">
            <verify-slider @getResult="getResult"></verify-slider>
          </el-row>
          <el-row class="my-loginbtn">
            <el-button type="primary" class="my-login-btns" @click="submitForm('ruleForm')" :loading="loading">登录</el-button>
            <el-button type="primary" class="my-login-btns" @click="linkRegitster">注册</el-button>
          </el-row>
          <!-- <el-form-item style="width:100%;" label-width="0px">
            <el-button type="primary" style="width:100%;" @click="submitForm('ruleForm')" :loading="loading">登录</el-button>
          </el-form-item> -->
          <!-- <p class="login-change" @click="changeMethod">切换手机短信登录</p> -->
          <div class="my-bottom">
            <div class="login-btns">
              <p @click="addFeedback" class="login-feedback">问题返馈</p>
              <p class="login-register">
                <img @click="changeMethod" v-if="loginType == 'phone'" class="my-small-img" src="~@/assets/images/6.png" alt="">
                <img @click="changeMethod" v-if="loginType == 'account'" class="my-small-img" src="~@/assets/images/5.png" alt="">
                <img @click="wxlogin" class="my-small-img" src="~@/assets/images/4.png" alt="">
              </p>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="login-bot-cont">
      技术支持：中科富安，
      <a target="_blank" href="https://beian.miit.gov.cn/">备案号：粤ICP备2022128787号 </a>
      ，联系电话：0760-88610046
    </div>
    <feedback-edit ref="editForm" @getData="getData"></feedback-edit>
    <findaccount ref="findaccount" @getData="getData"></findaccount>
    <mydialog ref="mydialog" title="请选择登录主体" width="500px">
      <div slot="content" class="my-list">
        <el-radio-group v-model="userName" class="my-radio">
          <el-radio
            v-for="(item, index) in userNameListL"
            :key="index"
            :label="item.value"> {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="forms-btns" slot="footer">
        <el-button type="primary" size="small" @click="closeModel()">确定</el-button>
      </div>
    </mydialog>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import feedbackEdit from './feedbackEdit.vue'
import findaccount from './findaccount.vue'
import mydialog from '@/components/mydialog.vue'
import verifySlider from '@/views/common/verifySlider.vue'
let _t = null
export default {
  name: 'login',
  components: {
    feedbackEdit,
    findaccount,
    mydialog,
    verifySlider
  },
  data() {
    return {
      loading: false,
      redirect: '',
      otherQuery: {},
      ruleForm: {
        userName: '',
        password: '',
        mobile: '',
        verificationCode: '',
        code: '',
        checked: true,
        codeText: ''
      },
      rules: {
        userName: [{ required: true,  trigger: "change", message: '请输入账号'}],
        password: [{ required: true,  trigger: "change", message: '请输入密码'}],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入手机验证码'}],
        mobile: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(_t.loginType == 'account'){
              callback()
            }
            if(!val){
              callback(new Error("请输入手机号码"))
            }else if(!baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号码"))
            }else{
              callback()
            }
          }
        }],
        code: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(_t.loginType == 'phone'){
              callback()
            }
            if(!val){
              callback(new Error("请输入验证码"))
            }else if( _t.ruleForm.codeText != val.toUpperCase()){
              callback(new Error("请输入正确的验证码"))
            }else{
              callback()
            }
          }
        }],
      },
      loginType: 'account',
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
      userName: '',
      userNameListL: [],
      confirmSuccess: false
    }
  },
  created() {
  },
  mounted(){
    // this.drawPic()
    _t = this
    this.getuserpwd()
    this.changeMethod(false)
  },
  methods: {
    delCodeandstate() { //函数作用：去除url中的code和state
      let path = ""
      for (let i in this.otherQuery) {
          if (i != "code" && i != "state") {
              path = path + "&" + i + "=" + this.otherQuery[i]
          }
      }
      path = path == "" ? "" : path.substring(1, path.length)
      path = path == "" ? "" : "/?" + path
      return path
    },
    wxlogin(){
      let redirect_uri = encodeURIComponent(`${this.redirect || '/'}${this.delCodeandstate}`)
      console.log(redirect_uri)
      // https://open.weixin.qq.com/connect/qrconnect?appid=wx4e291a19a73ebc8b&redirect_uri=https%3A%2F%2Fu.wqketang.com%2Fwebserver%2Fuser%2Flogin%2Fwx&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + CONSTPARAMS.appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_base#wechat_redirect"
    },
    focusInput(name){
      document.getElementById(name).className = 'my-login-input'
    },
    blurInput(name){
      document.getElementById(name).className = ''
    },
    getResult(){
      this.confirmSuccess = true
    },
    changeMethod(flag = true){
      if(flag){
        this.loginType = this.loginType == 'account' ? 'phone' : 'account'
      }
      if(this.loginType == 'account'){
        this.rules.userName[0].required = true
        this.rules.password[0].required = true
        this.rules.code[0].required = true
        this.rules.mobile[0].required = false
        this.rules.verificationCode[0].required = false
      }else{
        this.rules.userName[0].required = false
        this.rules.password[0].required = false
        this.rules.code[0].required = false
        this.rules.mobile[0].required = true
        this.rules.verificationCode[0].required = true
      }
    },
    sendMsg(prop){
      utils.sendMsg(this, prop, 'login')
    },
    getData(){},
    linkRegitster(){
      this.$router.push({
        path: '/register'
      })
    },
    addFeedback(){
      this.$refs.editForm.initData(`新增问题反馈`, 'add')
    },
    findaccount(){
      this.$refs.findaccount.initData(`找回账号和密码`, 'add')
    },
    getuserpwd() {
      let info = utils.getStore('userAccount')
      if(info && info.checked){
        let loginType = utils.getStore('loginType', false) || 'account'
        this.ruleForm.userName = info.userName
        this.ruleForm.password = info.password
        this.ruleForm.mobile = info.mobile
        this.ruleForm.checked = info.checked
        this.loginType = loginType
      }
    },
    submitForm(formName) {
      let _this = this
      if(this.$vnode.parent) this.$vnode.parent.componentInstance.cache = {}
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.submitData()
        } else {
          return false
        }
      })
    },
    async submitData(){
      let _this = this,
        type = _this.loginType
      if(!this.confirmSuccess){
        this.message('请拖动滑块验证', "error")
        return
      }
      _this.loading = true
      if(type == 'account'){
        request.commonsignin({
          userName: _this.ruleForm.userName,
          password: _this.ruleForm.password
        }).then((res) => {
          _this.loading = false
          if(res.code == 200){
            utils.setStore('myToken', res.data?.tokenValue || '')
            utils.setStore('userAccount', _this.ruleForm)
            utils.setStore('loginType', _this.loginType)
            _this.getUserInfo(_this.ruleForm.userName)
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      }else if(type == 'phone'){
        let contObj = this.ruleForm,
          flag = await utils.codeCheck('login', contObj.mobile, contObj.verificationCode) || false
        if(!flag) {
          _this.loading = false 
          return
        }
        request.commonsignmobile({
          mobile: contObj.mobile
        }).then(res => {
          _this.loading = false 
          if(res.code == 200){
            let finData = res.data || []
            if(finData.length == 1){
              _this.getToken(finData[0].userName, finData[0].loginId)
            }else{
              _this.transData(finData)
            }
          }else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      }
    },
    getToken(userName, loginId){
      let _this = this,
        flag  = CONSTPARAMS.needToken
      if(!flag){
        _this.setStores(userName)
        return
      }
      request.getToken({
        loginId: loginId || '',
        userName: userName || '',
      }).then(res => {
        if(res.code == 200){
          if(res.data){
            utils.setStore('myToken', res.data?.tokenValue || '')
            _this.setStores(userName)
            // utils.setStore('userAccount', _this.ruleForm)
            // utils.setStore('loginType', _this.loginType)
            // _this.getUserInfo(userName)
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    setStores(userName){
      let _this = this
      utils.setStore('userAccount', _this.ruleForm)
      utils.setStore('loginType', _this.loginType)
      _this.getUserInfo(userName)
    },
    transData(arr = []){
      let list = []
      if(arr.length){
        arr.forEach(cur => {
          list.push({ label: cur.bodyName || '', value: cur.userName || '', loginId: cur.loginId || '' })
        })
      }
      this.userNameListL = list
      this.userName = ''
      this.$refs.mydialog.showModel()
    },
    closeModel(){
      if(!this.userName){
        this.message('请选择登录主体', "error")
        return 
      }
      let userName = this.userName,
        list = this.userNameListL || [],
        loginId = ''
      list.forEach(cur => {
        if(cur.userName = userName){
          loginId = cur.loginId
        }
      })
      this.getToken(userName, loginId) 
      this.$refs.mydialog.closeModel()
    },
    getUserInfo(userName){
      let _this = this
      utils.setStore('baseName', '')
      utils.reGetAddr()

      request.commonuserinfo({
        'userName': userName
      }).then(res2 => {
        if(res2.code == 200){
          utils.setStore('userInfo', res2.data)
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery
          })
          // location.reload()
        }else{
          _this.message(res2.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    codeChange: function() {
      let _this = this;
      this.drawPic(_this);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    /**生成一个随机色**/
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    /**绘制验证码图片**/
    drawPic(that) {
      let ctx = document.getElementById('canvas').getContext('2d'),
        _this = this
      /**绘制背景色**/
      ctx.fillStyle = this.randomColor(180, 240); //颜色若太深可能导致看不清
      ctx.fillRect(0, 0, 300, 400)
      /**绘制文字**/
      let codeText = '';
      let str = 'ABCEFGHJKLMNPQRSTWXY123456789';
      for (let i = 0; i < 4; i++) {
        let txt = str[this.randomNum(0, str.length)];
        ctx.fillStyle = this.randomColor(50, 160); //随机生成字体颜色
        ctx.font = this.randomNum(90, 126) + 'px SimHei'; //随机生成字体大小
        let x = 30 + i * 65;
        let y = this.randomNum(100, 140);
        let deg = this.randomNum(-20, 20);
        //修改坐标原点和旋转角度
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(txt, 5, 0);
        codeText = codeText + txt;
        //恢复坐标原点和旋转角度
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y);
      }
      /**绘制干扰线**/
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(150, 255);
        ctx.beginPath();
        ctx.moveTo(this.randomNum(0, 400), this.randomNum(2, 320));
        ctx.lineTo(this.randomNum(0, 400), this.randomNum(2, 320));
        ctx.stroke();
      }
      /**绘制干扰点**/
      for (let i = 0; i < 20; i++) {
        ctx.fillStyle = this.randomColor(130, 255);
        ctx.beginPath();
        ctx.arc(this.randomNum(0, 400), this.randomNum(2, 320), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
      _this.ruleForm.codeText = codeText
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
},
}
</script>

<style scoped>
.login-scan{
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 100;
}
.login-scan img{
  width: 150px;
}
.login-scan p{
  color: #fff;
  font-size: 12px;
}
.login-cont{
  border-radius: 10px;
  margin: 0px auto;
  width: 800px;
  padding: 55px 35px 15px 35px;
  position: relative;
}
.login-title{
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.login-tilt-cont{
  padding-left: 10px;
  text-align: center;
  font-size: 22px;
}
.login-title1{
  padding-bottom: 5px;
}
.login-title2{
  font-size: 26px;
}
.login-logo{
  width: 100px;
}
.login-logo img{
  width: 100%;
}
.login-cont-desc{
  display: flex;
  border-radius: 30px;
}
.login-img{
  width: 490px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border: 1px solid #fff;
}
.login-img img{
  width: 450px;
}
.link{
  cursor: pointer;
  font-size: 14px;
}
.login-container ::v-deep .el-input-group__prepend{
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  background-color: #fff;
}
.login-container ::v-deep .el-input__inner, 
.login-container ::v-deep .el-input-group__append{
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: none;
}

.login-container ::v-deep .el-input__inner:focus{
  border-color: #409EFF;
}

.el-form-item.is-error ::v-deep .el-input-group__prepend,
.el-form-item.is-error ::v-deep .el-input__inner{
  border-color: #F56C6C;
}



.check-btn ::v-deep .el-input__inner{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-left: none;
}

.my-login-input ::v-deep .el-input-group__prepend{
  border-color: #409EFF;
}

.login-wrap {
  box-sizing: border-box;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url('~@/assets/images/1.png') center no-repeat #085efd;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  min-height: 654px;
  overflow: auto;
}
.login-container {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-radius: 10px; */
  margin: 0px auto;
  width: 300px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  /* border: 1px solid #eaeaea; */
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}
.login-bot-cont{
  align-self: flex-end;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  color: #fff;
  font-size: 14px;
}
.login-bot-cont a{
  color: #fff;
  text-decoration: none;
}
.title {
  margin: 0px auto 25px auto;
  text-align: center;
  color: #080808;
  font-size: 20px;
}
.remember {
  margin-bottom: 15px;
}
.my-loginbtn{
  display: flex;
  justify-content: space-between;
}
#verificationCode ::v-deep .el-input-group__append button.el-button{
  background-color: #1C85FB;
  border: 1px solid #1C85FB;
}
#verificationCode .my-input-btn-gray .el-input-group__append button.el-button {
  background: #a5a7a9;
  border: 1px solid #a5a7a9;
  color: #fff;
  height: 46px;
  cursor: default;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.login-container ::v-deep .el-input-group__append, .login-container ::v-deep  .el-input-group__prepend{
  padding: 0 10px;
}
.my-login-btns{
  background-color: #1C85FB;
  border: 1px solid #1C85FB;
  width: 120px;
  height: 40px;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 2px;
}
.my-small-img{
  width: 30px;
  padding-left: 6px;
}
.pb-15{
  margin-bottom: 20px;
}
.code-box {
  text-align: right;
}
.codeimg {
  height: 40px;
}
.canvas{
  height: 40px;
  width: 110px;
  cursor: pointer;
  margin-left: 16px;
}
.login-change{
  font-size: 15px;
  cursor: pointer;
  padding: 0 0 10px 0;
  letter-spacing: 1px;
}
.my-bottom{
  bottom: 1px;
  position: absolute;
  width: 89%;
}
.login-btns{
  display: flex;
  justify-content: space-between;
  padding: 30px 0 10px;
  letter-spacing: 1px;
  align-items: center;
}
.login-feedback,  .login-register{
  cursor: pointer;
  font-size: 15px;
}
.my-list{
  max-height: 400px;
  overflow: auto;
}
.my-radio ::v-deep .el-radio{
  margin: 10px 30px 20px 20px;
  display: block;
}

</style>