<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    v-if="closeFlag"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <div v-show="tabName == 'first'">
            <el-row>
              <el-col :span="24">
                <el-form-item label="是否有营业执照？：" prop="bodyType">
                  <el-radio-group v-model="ruleForm.bodyType" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.bodyType"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="ruleForm.bodyType == '02' ? '身份证号码：' : '主体名称或统一社会信用代码：'" prop="info">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.info }}</p>
                  <el-input clearable v-else v-model="ruleForm.info" :placeholder="ruleForm.bodyType == '02' ? '请输入身份证号码' : '请输入主体名称或统一社会信用代码'" :maxlength="ruleForm.bodyType == '02' ? 18 : 64" show-word-limit :type="ruleForm.bodyType == '02' ? 'text' : 'textarea'" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item prop="code" label="验证码：">
                  <el-input type="text" v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="code-box" label-width="0px">
                <canvas class="canvas" id="canvas2" @click='codeChange'></canvas>
              </el-col>
            </el-row>
          </div>
          <div v-show="tabName == 'second'">
            <el-row>
              <el-col :span="24">
                <el-form-item label="手机号：" prop="phone">
                  <p class="view-cont">{{ ruleForm.tempPhone }}</p>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="手机验证码：" prop="verificationCode">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                  <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                    <el-button slot="append" v-if="sendFlag" @click="sendMsg('phone')" type="primary">获取验证码</el-button>
                    <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <p class="tips-gray tips">验证码10分钟有效</p>
            </el-row>
          </div>
          <div v-show="tabName == 'third'">
            <p class="forget-look">账号：{{ruleForm2.userName || ''}}</p>
            <p class="forget-look">密码：{{ruleForm2.password || ''}}</p>

          </div>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="tabName != 'third'" :loading="loading" size="small" @click="submitForm()">下一步</el-button>
      <el-button type="primary" v-if="tabName == 'third'" size="small" @click="closeModel">完成</el-button>
      <el-button @click="closeModel" v-if="tabName != 'third'" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
let _t = null
export default {
  name: "findaccount",
  components: {
  },
  props:{
  },
  data() {
    return {   
      rules: {
        code: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入验证码"))
            }else if( _t.ruleForm.codeText != val.toUpperCase()){
              callback(new Error("请输入正确的验证码"))
            }else{
              callback()
            }
          }
        }],
        bodyType: [{ required: true,  trigger: "change", message: '请选择是否有营业执照'}],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入手机验证码'}],
        info: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            let bodyType = _t.ruleForm.bodyType || ''
            if(!val){
              if(!bodyType || bodyType == '01'){
                callback(new Error("请输入主体名称或统一社会信用代码"))
              }else{
                callback(new Error("请输入身份证号码"))
              }
            }else{
              if(bodyType == '02' && val.length != 18){
                callback(new Error("请输入正确的身份证号码"))
              }
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'add',
      ruleForm: {},
      ruleForm2: {
        userName: '',
        password: '',
      },
      initForm: {
        bodyType: '',
        info: '',
        code: '',
        bodyId: '',
        phone: '',
        tempPhone: '',
        verificationCode: '  '
      },
      selectObj: {
        bodyType: [
          { label: '是', value: '01' },
          { label: '否', value: '02' }
        ]
      },
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
      tabName: 'first'
    };
  },
  created() {
	},
  mounted(){
    _t = this
  },
  methods: {
    sendMsg(prop){
      utils.sendMsg(this, prop, 'forget')
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        // let id = await utils.getNewId(),
        let obj = {
          ...this.initForm,
          ...transobj,
          // feedbackId: id,
        }
        this.tabName = 'first'
        this.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
      setTimeout(() => {
        this.drawPic()
      }, 100);
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.loading = true
          if(_this.tabName == 'first'){
            request.commongetBodyInfoByUserAccount({
              info: _this.ruleForm.info,
              bodyType: _this.ruleForm.bodyType
            }).then((res) => {
              if(!res.data){
                _this.message('未查询到主体信息，请输入正确的信息！', "error")
              }else{
                _this.tabName = 'second'
                _this.ruleForm.bodyId = res.data?.bodyId || ''
                _this.ruleForm.verificationCode = ''
                _this.ruleForm.phone = res.data?.mobilePhoneNumber || ''
                _this.ruleForm.tempPhone = res.data?.mobilePhoneNumber.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
              }
              _this.loading = false
            }).catch(e => {
              console.log(e)
              _this.loading = false
            })
          }else{
            _this.submitData()
          }
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this
      _this.loading = true
      request.commongetUserAccount({
        phone: _this.ruleForm.phone,
        code: _this.ruleForm.verificationCode,
        bodyId: _this.ruleForm.bodyId,
      }).then((res) => {
        if(res.code == 200){
          _this.tabName = 'third'
          _this.ruleForm2.userName = res.data.userName || ''
          _this.ruleForm2.password = res.data.password || ''
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    },
    codeChange: function() {
      let _this = this;
      this.drawPic(_this);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    /**生成一个随机色**/
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    /**绘制验证码图片**/
    drawPic(that) {
      let ctx = document.getElementById('canvas2').getContext('2d'),
        _this = this
      /**绘制背景色**/
      ctx.fillStyle = this.randomColor(180, 240); //颜色若太深可能导致看不清
      ctx.fillRect(0, 0, 300, 400)
      /**绘制文字**/
      let codeText = '';
      let str = 'ABCEFGHJKLMNPQRSTWXY123456789';
      for (let i = 0; i < 4; i++) {
        let txt = str[this.randomNum(0, str.length)];
        ctx.fillStyle = this.randomColor(50, 160); //随机生成字体颜色
        ctx.font = this.randomNum(90, 126) + 'px SimHei'; //随机生成字体大小
        let x = 30 + i * 65;
        let y = this.randomNum(100, 140);
        let deg = this.randomNum(-20, 20);
        //修改坐标原点和旋转角度
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(txt, 5, 0);
        codeText = codeText + txt;
        //恢复坐标原点和旋转角度
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y);
      }
      /**绘制干扰线**/
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(150, 255);
        ctx.beginPath();
        ctx.moveTo(this.randomNum(0, 400), this.randomNum(2, 320));
        ctx.lineTo(this.randomNum(0, 400), this.randomNum(2, 320));
        ctx.stroke();
      }
      /**绘制干扰点**/
      for (let i = 0; i < 20; i++) {
        ctx.fillStyle = this.randomColor(130, 255);
        ctx.beginPath();
        ctx.arc(this.randomNum(0, 400), this.randomNum(2, 320), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
      _this.ruleForm.codeText = codeText
    },
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
.codeimg {
  height: 40px;
}
.canvas{
  height: 40px;
  width: 110px;
  cursor: pointer;
  margin-left: 16px;
}
.tips{
  padding-left: 35px;
}
.forget-look{
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  padding-left: 100px;
}
</style>


